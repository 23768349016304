<template>
    <div>
        <div :style="{width: this.$oucy.pageWidth+'px', display: 'inline-block'}">
            <div class="middle_middle">
                <div style="float:left;margin:12px 0 0 28px;">
                </div>
                <div class="wangge" @click="ChangeType(2)">
                    <img src="@/assets/icon/liebiao.png" class="hover" alt="" :style="type==2?'display:none':'display:block'">
                    <img src="@/assets/icon/liebiao_hover.png" alt="" :style="type==2?'display:block':'display:none'">
                    <span :style="type==2?'color:#2090ff':''">列表模式</span>
                </div>
                <div class="wangge" @click="ChangeType(1)">
                    <img src="@/assets/icon/wangge_hover.png" class="hover" alt="" :style="type==1?'display:none':'display:block'">
                    <img src="@/assets/icon/wangge.png" alt="" :style="type==1?'display:block':'display:none'">
                    <span :style="type==1?'color:#2090ff':''">网格模式</span>
                </div>
            </div>
            <div class="middle">
                <div class="grid" v-if="type==1">
                    <ul>
                        <li v-for="v of content" @click="$oucy.go('/product?spuId='+v.id)" class="pointer" :key="v.id">
                            <img :src="$oucy.ossUrl+v.spuImgs[0].imgUrl" v-if="v.spuImgs && v.spuImgs[0] && v.spuImgs[0].imgUrl" class="spuImgs">
                            <p class="title line-2" style="height:42px" v-html="v.spuName"></p>
                            <el-row>
                                <el-col :span="12">
                                    <span class="price" v-if="v.spuMinPrise*1!=0">
                                        ￥{{v.spuMinPrise}}
                                    </span>
                                    <span class="price" v-else>面议</span>
                                </el-col>
                                <el-col :span="12"><span class="stock" style="float: right">库存&nbsp{{v.quantity}}</span></el-col>
                            </el-row>
                            <div class="brand_name df-ai-c">
                                <el-image :src="$oucy.ossUrl + v.enterpriseAvata" class="enterpriseAvata" fit="cover"></el-image>
                                <span style="line-height: 15px;">{{v.enterpriseShopName}}</span>
                            </div>
                            <div class="add">
                                <el-button type="text" @click.stop="addStock(v)">加入进货车</el-button>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="liebiao" v-if="type==2">
                    <ul>
                        <li v-for="v of content" @click="$oucy.go('/product?spuId='+v.id)" class="pointer" :key="v.id">
                            <table>
                                <tr>
                                    <td width="180px">
                                        <img :src="$oucy.ossUrl+v.spuImgs[0].imgUrl" v-if="v.spuImgs && v.spuImgs[0] && v.spuImgs[0].imgUrl" style="width: 96px">
                                    </td>
                                    <td width="600px">
                                        <p class="title line-2" v-html="v.spuName"></p>
                                    </td>
                                    <td width="320px">
                                        <span class="brand_name" style="line-height: 15px;" v-if="v.brand">{{v.brand.brandName}}</span>
                                    </td>
                                    <td width="280px">
                                        <span class="price" v-if="v.spuMinPrise*1!=0">
                                            ￥{{v.spuMinPrise}}
                                        </span>
                                        <span class="price" v-else>面议</span>
                                    </td>
                                    <td width="220px">
                                        <span class="stock">库存&nbsp{{v.quantity}}</span>
                                    </td>
                                    <td width="300px" class="text-right">
                                        <el-button type="text">加入进货车</el-button>
                                    </td>
                                </tr>
                            </table>
                        </li>
                    </ul>
                </div>
            <div class="pagination">
                <div v-if="noMore && (!content || !content.length) && queryObj.start" class="notData">
                    <!-- <img src="@/assets/images/index/notData.png" alt=""> -->
                    <div>- 暂无匹配数据 -</div>
                </div>
                <div class="loading p-t-30">
                    <p v-if="loading && !noMore">加载中...</p>
                    <p v-if="noMore && content && content.length">- 没有更多了 -</p>
                </div>
                <scrollPage @load="load" :hasMore="!noMore"></scrollPage>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import { spu } from "@/service/index.js"
import { localSet, localGet, localDel } from "@/store/store";

export default {
    name: "SearchImage",
    data() {
        return {
            type: 1,
            requestObj: {},
            // 请求对象
            queryObj: {
                imgUrl: null,
                limit: 30,
                start: 0,
            },
            loading: false,
            noMore: false,
            content:[]

        }
    },
    watch: {
        $route: {
            handler(to, from) {
                this.content = []
                this.queryObj.start = 0
                this.noMore =false
                this.loading = false
                this.queryObj.imgUrl = to.query.imgUrl
                this.load()
                console.log('$route handler')
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
    },
    methods: {
        ChangeType(num) {
            this.type = num;
        },
        load() {
            if (!this.noMore && !this.loading) {
                this.searchImage()
            }
            this.loading = true
        },
        searchImage() {
            spu.searchImage(this.queryObj).then(res => {
                if(this.queryObj.start==0){
                    this.content=res.content || []
                }else{
                    this.content.push(...res.content || [])
                }
                console.log()
                this.loading = false
                this.noMore = res.last
                ++this.queryObj.start
            },err=>{
            })
        },

    }
}
</script>
<style scoped>
.left_nav {
    margin-top: 20px;
}

.left_nav .el-menu-item {
    /*margin-left:20px;*/
    z-index: 1000;
}

.el-menu {
    border-right: 0 !important;
    min-height: 500px;
    padding: 30px 0;
}

.nav img {
    margin-right: 8px;
}

.nav:hover .icon1 {
    display: none;
}

.nav:hover .icon2 {
    display: inline-block;
}

.icon2 {
    display: none;
}

.bold_span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    /*margin: 30px 0 0 0px;*/
    /*display: inline-block;*/
    /*padding: 20px;*/
}

.top_sort {
    margin-top: 20px;
    margin-left: 10px;
    background-color: white;
    padding: 30px 0 5px 20px;
}

.auth {
    display: flex;
    margin-bottom: 30px;
}

.auth>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
}

.auth .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
}

.auth .el-button--text.select {
    color: #409EFF;
}

.el-button--text:hover {
    /*color: rgb(32,144,255) !important;*/
}

.el-button--mini {
    border: none;
}

.el-button--mini:hover {
    /*background-color: rgb(32,144,255) !important;*/
}

.el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

.middle_middle {
    height: 52px;
    background-color: #FFFFFF;
    margin: 10px 0 10px 10px;
}

.middle_middle span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-right: 10px;
}

.middle_middle .el-input {
    width: 97px;
    margin-right: 10px;
}

.list>>>.el-table__row>td {
    /*去除表格线*/
    border: none;
}

.list>>>.el-table th.is-leaf {
    /*去除上边框*/
    border: none;
}

.list>>>.el-table::before {
    /*去除下边框*/
    height: 0;
}

.wangge {
    float: right;
    margin-right: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.wangge span {
    font-size: 14px;
    margin-left: 8px;
    color: #666666;
}

.middle {
    background: #FFFFFF;
    margin-left: 10px;
    margin-top: -15px;
}

.middle ul li:nth-child(4n) {
    margin-right: 0px;
}

.middle ul {
    text-align: left;
}

.middle ul li {
    width: 234px;
    /*height: 340px;*/
    background-color: #FFFFFF;
    padding: 10px;
    /*float: left;*/
    display: inline-block;
    list-style: none;
    cursor: pointer;
}

.title {
    font-size: 14px;
    font-family: Microsoft YaHei;
    /*font-weight: bold;*/
    color: #666666;
    margin-top: 10px;
    height: 36px;
}

.brand_name span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    float: left;
    margin-right: 5px;
}

.price {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #E92121;
    line-height: 40px;
}

.stock {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #AAAAAA;
    line-height: 50px;
}

.add .el-button--text {
    width: 100%;
    /*height: 29px;*/
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
    border: 1px solid #2090FF;
    border-radius: 2px;
    margin-top: 15px;
    /*line-height: 29px;*/
}

.pagination {
    margin-top: 5px;
   padding-bottom: 30px;
   margin-bottom: 50px;
}


.liebiao ul li {
    width: 1220px;
    height: 100px;
    background-color: #FFFFFF;
    list-style: none;
    /*margin-left: -20px;*/
}

table {
    text-align: left;
    margin-top: 15px;
}

.liebiao .el-button--text {
    width: 90px;
    /*height: 29px;*/
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
    border: 1px solid #2090FF;
    border-radius: 2px;
}

.el-page-header__title {
    background-color: red;
}

.wangge {
    float: right;
    margin: 15px 30px 0 0;
    display: flex;
    align-items: center;
    cursor: pointer;

}

.wangge span {
    font-size: 14px;
    margin-left: 8px;
    color: #666666;
}

.brand_name {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    /*float: left;*/
    height: 15px;
}




.spuImgs {
    width: 234px;
    height: 234px;
    background-color: #f1f1f1;
    border: 1px solid #eaeaea;

}

.spuImgs2 {
    width: 96px;
    height: 96px;
    background-color: #f1f1f1;
    border: 1px solid #eaeaea;

}

.enterpriseAvata {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-right: 10px;

}

.sputime {
    margin-top: 10px;
    text-align: center;
}


.loading {
    text-align: center;
    font-size: 14px;
    color: #aaaaaa;
    margin-top: 20px;
}

.notData {
    font-size: 14px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: center;
    color: #aaaaaa;
    padding: 60px 0;

    img {
        width: 350px;
    }
}
</style>